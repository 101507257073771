
import { Component, Vue, Watch } from 'vue-property-decorator';

import { ValidationObserver } from 'vee-validate';
import { mdiEye, mdiEyeOff } from '@mdi/js';

// Components
import Title from '@/components/common/Title.vue';
import OnboardLayout from '@/components/OnboardLayout.vue';
import { UserService, AdminService, FeatureService } from '@/services';
import { IUserNotificationSettings, IUserRegister } from '@/types/user.types';
import { UserModule } from '@/store/modules';
import { OnboardingSteps } from '@/components/onboarding';
import { ActiveTenant } from '../../../active-tenant';
import { tenants } from '../../../tenant-config';
import { ICauseAndCategorySelectOptions, ICauseCategorySettings, ICauseSettings } from '@/types/cause.types';
import { RegisterTooltip } from './components';

@Component({
  components: {
    Title,
    ValidationObserver,
    OnboardLayout,
    OnboardingSteps,
    RegisterTooltip
  }
})
export default class Register extends Vue {
  visible = false;
  agreement = false;
  receiveNotificationSms = false;
  showAgreementError = false;

  currentTenant = tenants[ActiveTenant];

  form = {
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    postalCode: '',
    mobilePhoneNumber: '',
    referralCode: this.$route.query.referralCode || undefined,
    roundingProgram: false,
    cashbackProgram: true,
    categoryId: '',
    causeId: this.$route.query.causeId || undefined
  };
  notificationSettings: IUserNotificationSettings = {
    sms: false,
    transactionSms: false,
    transactionEmail: false,
    marketingSms: false,
    marketingEmail: false
  };

  isMultipleCauseEnabled = false;
  causesCategories: ICauseCategorySettings[] = [];
  causes: ICauseSettings[] = [];
  categoryOptions: ICauseAndCategorySelectOptions[] | null = null;
  causeOptions: ICauseAndCategorySelectOptions[] | null = null;

  get appendIcon(): string | null {
    return this.visible ? mdiEye : mdiEyeOff;
  }
  get type(): string {
    return this.visible ? 'text' : 'password';
  }

  async onRegister(): Promise<void> {
    if (this.agreement) {
      const referralCode = this.form.referralCode;
      if (referralCode === '') {
        // We cannot send an empty string to the API
        this.form.referralCode = undefined;
      }
      try {
        this.form.roundingProgram = !UserModule.cashbackOnly;
        await UserService.register(this.form as IUserRegister);

        let user = await UserService.fetchUser();
        UserModule.setUser(user);
      } catch (e: any) {
        const errorMsg = this.$getError(e);
        this.$notifyError(errorMsg);

        return;
      }

      // NOTE: Don't reset submission state before refreshing page!

      // Force a page refresh to better clean up app state??
      this.$router.replace({
        path: '/card-setup'
      });
    } else {
      this.showAgreementError = true;
    }
  }

  onToggleVisibility(): void {
    this.visible = !this.visible;
  }

  toggleAgreement() {
    this.showAgreementError = false;
  }

  get mobileFormated() {
    return Vue.filter('phone')(this.form.mobilePhoneNumber);
  }

  set mobileFormated(input: string) {
    const clean = input.replaceAll(' ', '');
    this.form.mobilePhoneNumber = clean.length < 11 ? clean : this.form.mobilePhoneNumber;
  }

  async getCausesCategories(): Promise<ICauseAndCategorySelectOptions[]> {
    try {
      const sort = 'name';
      this.causesCategories = await AdminService.fetchCauseCategories(sort);
      this.categoryOptions = this.causesCategories.map((category) => ({
        label: category.name,
        value: category.id
      }));
      return this.categoryOptions;
    } catch (e: any) {
      this.$notifyError('Something went wrong');
      return [];
    }
  }

  async getCausesByCategory(categoryId?: string): Promise<ICauseAndCategorySelectOptions[]> {
    try {
      this.causes = await AdminService.fetchCauses(categoryId);
      this.causeOptions = this.causes.map((cause) => ({
        label: cause.name,
        value: cause.id
      }));
      return this.causeOptions;
    } catch (e: any) {
      this.$notifyError('Something went wrong');
      return [];
    }
  }

  async created(): Promise<void> {
    await this.getCausesCategories();
    await this.getCausesByCategory();
    const response = await FeatureService.getIsMultipleCauseEnabled();
    this.isMultipleCauseEnabled = response.isMultipleCauseEnabled;
    if (this.$route.query.causeId) {
      const causeId = this.$route.query.causeId as string;
      const cause = await AdminService.fetchCauses(undefined, causeId);
      this.form.categoryId = cause[0].category.id;
    }
  }

  @Watch('form.categoryId')
  onCategoryChange(categoryId: string): void {
    this.getCausesByCategory(categoryId);
  }
}
